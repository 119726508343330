.btn {
  @extend %bebas;
}

.btn-danger {
  color: $white !important;
}

//.btn-submit:not(.btn-danger) {
//  background-color: $success !important;
//}