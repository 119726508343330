/**
 * Anakin Bootstrap 4 styles.
 */
@import "includes/variables";
@import "includes/fonts";
@import "includes/mixins";

@import "../../../../yii2-skeleton/assets/admin/scss/admin";

@import "includes/alerts";
@import "includes/buttons";
@import "includes/card";
@import "includes/forms";
@import "includes/jquery";
@import "includes/home";
@import "includes/navs";
@import "includes/tables";