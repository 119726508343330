@font-face{
	font-family:'Bebas Neue';
	src:url('../fonts/bebasneue_bold-webfont.woff2') format('woff2'),
	url('../fonts/bebasneue_bold-webfont.woff') format('woff');
	font-weight:bold;
	font-style:normal;
}

@font-face{
	font-family:'ATC Overlook';
	src:url('../fonts/atcoverlook-regular-webfont.woff2') format('woff2'),
	url('../fonts/atcoverlook-regular-webfont.woff') format('woff');
	font-weight:normal;
	font-style:normal;

}

@font-face{
	font-family:'ATC Overlook';
	src:url('../fonts/atcoverlook-heavy-webfont.woff2') format('woff2'),
	url('../fonts/atcoverlook-heavy-webfont.woff') format('woff');
	font-weight:700;
	font-style:normal;

}
