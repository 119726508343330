.card-header{
	margin-bottom:$app-card-margin-bottom;
	border-bottom-width:3px;
	letter-spacing:.1em;
	text-transform: uppercase;
}

.item{

	.card{
		border:1px solid $border-color;
	}

	.card-header{
		margin-bottom:15px;
		padding:15px;
		border:1px solid $border-color;
	}

	.card-body{
		padding-bottom:15px;
	}
}