/*
Config.
 */
$enable-shadows: true;

$container-max-widths: (
        xl: 1500px
) !default;

/*
Colors.
 */
$primary: #000;
$success: #74bcd1;
$danger: #ffb7bb;
$info: #ede26b;

$body-text: #000;
$body-bg: #fff;

$link-hover-color: #74bcd1;
$anakin-success-bg: #d1e8ef;
$app-info-text: $body-text;

$btn-primary-bg: #000;
$btn-secondary-bg: $anakin-success-bg;
$btn-secondary-hover-bg: darken($anakin-success-bg, 10%);
$btn-secondary-active-bg: $link-hover-color;

$trail-ins-bg: $anakin-success-bg;

/*
Grid.
 */

$grid-breakpoints: (
        xs: 0,
        md: 768px,
        lg: 1024px,
        xl: 1440px
) !default;

/*
Spacers.
 */
$app-margin-bottom: 40px;
$app-padding-y: 15px;
$app-padding-x: 0;
$app-body-padding: 110px 0 50px;
$app-header-margin: 40px 0 20px;

/*
Border.
 */
$border-radius: 0;
$border-width: 0;
$border-color: #ebebeb;

/*
Text.
 */
$font-family-base: 'ATC Overlook', sans-serif;
$headings-font-weight: 700;
$link-hover-decoration: none;

/*
Navs.
 */
$nav-link-padding-y: 10px;
$nav-link-padding-x: 15px;
$app-submenu-link-margin: 0 5px 10px;

/*
Navbar.
 */
$app-navbar-nav-link-padding-y: 20px;
$navbar-nav-link-padding-x: 20px;
$app-navbar-background: $body-bg;
$app-navbar-dark-hover-bg: $border-color;
$navbar-dark-active-bg: $body-text;
$navbar-dark-color: $body-text;
$navbar-dark-hover-color: $body-text;
$navbar-dark-active-color: $body-bg;
$anakin-navbar-dark-border-color: $border-color;
$navbar-border: 1px solid $anakin-navbar-dark-border-color;

$navbar-dark-toggler-icon-hex-color: '000';

/*
Breadcrumbs.
 */
$breadcrumb-bg: transparent;
$breadcrumb-color: $body-text;
$breadcrumb-padding-y: 0;
$breadcrumb-divider-color: $body-text;

/*
Alert.
 */
$alert-padding-x: 15px;

/*
Card.
 */
$card-spacer-x: 15px;
$card-spacer-y: 15px;
$card-cap-bg: transparent;
$card-border-width: 0;
$card-border-color: #000;
$card-danger-bg: $card-cap-bg;
$card-danger-border: $danger;
$card-danger-color: $danger;


/*
Input.
 */
$input-btn-padding-y: 12px;
$input-btn-padding-x: 20px;
$app-form-label-font-weight: normal;
$input-line-height: 20px;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-y;
$input-height: 46px;

$app-form-content-label-padding-top: false;

/*
List group.
 */
$list-group-item-padding-x: 15px;
$list-group-action-color: $body-text;

/*
Title.
 */
$app-header-border-bottom: transparent;
$app-header-line-height: inherit;

/*
Panel.
 */
$panel-body-padding: 15px 0;
$panel-heading-padding: 15px 0;

$panel-default-text: $body-text;
$panel-default-border: $body-text;
$panel-default-heading-bg: transparent;
$panel-danger-heading-bg: transparent;

/*
Lists.
 */
$list-group-border: #ebebeb;
$list-group-link-color: $body-text;

/*
Buttons.
 */
$btn-box-shadow: none;

/*
Forms.
 */
$input-color: $body-text;
$input-border-width: 1px;
$input-border-color: transparent;
$input-group-addon-bg: #fff;
$input-color-placeholder: #9f9f9f;
$input-box-shadow: inset 2px 2px 6px 0 rgba(0, 0, 0, 0.13) !important;

$hr-border-width: 1px;
$hr-border: #ebebeb;

$app-form-sticky-border-width: $hr-border-width !default;

/*
Tables.
 */
$table-cell-padding: 15px;
$table-border-color: #fff;

/*
Pagination.
*/
$pagination-hover-color: $body-text;
$dropdown-spacer: 0;


/*
Badge.
 */
$app-badge-bg: transparent;
$app-badge-color: #000 !important;
$badge-font-weight: 700;
$badge-padding-y: 3px;
$badge-padding-x: 5px;
$badge-border-radius: 0;

/*
Upload.
 */
$app-upload-progress-top: 60px !default;
//$app-upload-progress-height: 10px !default;
$app-upload-progress-bg: $primary;

/*
Datepicker
 */
$datepicker-td-border: 1px solid transparent;

/*
Trail.
 */
$trail-ins-bg: #B7DABB;
